<template>
  <v-col v-if="postCategory.id" cols="4">
    <div class="font-weight-bold text-h5 mb-4">
      Folder bài viết
    </div>

    <v-form ref="formRef">
      <v-card class="tp-filter-scroll pa-5" flat>
        <v-card-title class="font-weight-bold text-h5 pa-0 mb-4">
          {{ postCategory.id ? "Cập nhật folder" : "Thêm folder" }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container class="pa-0" fluid>
          <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
            Kích thước ảnh khuyến nghị
            <span class="font-weight-bold">là 2400 x 640 px</span>
          </div>
          <tp-input-image
            :acceptedFileTypes="['image/*']"
            :maxFileSize="500 * 1024"
            :src.sync="postCategory.thumbnail"
            allowCompress
            allowFileSize
            allowFileTypeValidation
            class="flex-grow-1"
            forceType="jpeg"
          ></tp-input-image>

          <v-row class="mt-4">
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên folder</div>
              <tp-text-field
                v-model="postCategory.name"
                class="text-body-1"
                dense
                flat
                outlined
                placeholder="Nhập tên folder"
                single-line
                solo
                validate="required"
              >
              </tp-text-field>
              <v-checkbox
                v-model="postCategory.status"
                :false-value="2"
                :true-value="1"
                class="mt-0"
                label="Folder public (user có thể truy cập và xem nội dung bên trong)"
              ></v-checkbox>
              <v-checkbox
                v-model="hasParent"
                class="mt-0"
                label="Folder có folder cha (ví dụ World cup sẽ có folder cha là Bóng đá)"
                @change="onHasParent"
              ></v-checkbox>
              <div v-if="hasParent" class="pl-8">
                <div class="font-weight-bold mb-2">Folder cha</div>
                <categories-single-select
                  v-model="postCategory.parent_id"
                  @change="selectedParent = $event"
                ></categories-single-select>
                <v-checkbox
                  v-model="postCategory.parent_show"
                  :false-value="2"
                  :true-value="1"
                  class="mt-0 d-flex flex-row align-start"
                  hide-details="auto"
                  label="Hiển thị như một tab con trong folder cha trên Front store"
                >
                </v-checkbox>
                <v-btn
                  class="px-1 mb-4"
                  color="primary"
                  small
                  text
                  @click="openModalFolderExample"
                  >Xem minh hoạ
                </v-btn>
                <div class="font-weight-bold mb-2">Thứ tự hiển thị</div>
                <v-select
                  v-model="postCategory.swap"
                  :items="activeChildrenFolderByParentId"
                  class="text-body-1"
                  dense
                  flat
                  item-text="order"
                  item-value="id"
                  outlined
                  placeholder="Nhập ưu tiên hiển thị"
                  single-line
                  solo
                  type="number"
                ></v-select>
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Hiển thị trên Front Store</div>

              <tp-autocomplete
                v-model="postCategory.sites"
                :items="ARRAY_LIST_MODEL_SITES"
                deletable-chips
                hide-details="auto"
                item-text="text"
                item-value="id"
                label="Site hiển thị"
                multiple
                placeholder="Chọn site hiển thị"
                small-chips
                validate="required"
              >
              </tp-autocomplete>

              <v-radio-group v-model="postCategory.show_type">
                <v-radio
                  v-for="(item, index) in SHOW_TYPE"
                  :key="index + 'SHOW_TYPE'"
                  :label="item.name"
                  :value="item.value"
                  >{{ item.name }}
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">
                Dạng filter hỗ trợ trên Front Store
              </div>
              <v-radio-group v-model="postCategory.search_type">
                <v-radio
                  v-for="(item, index) in SEARCH_TYPE"
                  :key="index + 'SEARCH_TYPE'"
                  :value="item.value"
                  class="d-flex flex-row align-start"
                >
                  <template #label>
                    <div class="d-flex flex-column justify-center align-start">
                      <span class="font-weight-bold"> {{ item.name }}</span>
                      <span class="text-body-2">{{ item.description }}</span>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="px-0 pt-6 pb-0 d-flex justify-end">
          <v-btn
            class="grey lighten-5 rounded-lg mr-2"
            color="grey--text text--darken-1"
            text
            @click="resetPostCategory()"
          >
            Hủy
          </v-btn>
          <v-btn
            v-if="postCategory.id"
            :loading="
              postCategoryStatusRequest.value === 'loading-deletePostCategory'
            "
            class="mr-2"
            color="error"
            depressed
            @click="deletePostCategory()"
          >
            Xóa
          </v-btn>

          <v-btn
            v-else
            :loading="
              postCategoryStatusRequest.value === 'loading-updatePostCategory'
            "
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            @click="createPostCategory()"
          >
            Thêm
          </v-btn>
          <v-btn
            v-if="postCategory.id"
            :loading="
              postCategoryStatusRequest.value === 'loading-createPostCategory'
            "
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            @click="updatePostCategory()"
          >
            Lưu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-col>
</template>

<script>
import { SHOW_TYPE, SEARCH_TYPE } from "@/modules/Website/constants/index";
import CategoriesSingleSelect from "@/modules/Website/components/CategoriesSingleSelect.vue";
import { toFormData } from "@vt7/utils";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";

export default {
  name: "CardFormCategory",
  components: {
    CategoriesSingleSelect
  },

  data() {
    return {
      SHOW_TYPE,
      SEARCH_TYPE,
      formData: null,
      formRef: null,
      hasParent: false,
      selectedParent: null,
      ARRAY_LIST_MODEL_SITES: ARRAY_LIST_MODEL_SITES
    };
  },

  computed: {
    activeChildrenFolderByParentId() {
      if (!this.selectedParent) {
        return [];
      }
      const childrens = this.selectedParent.children.map((item, index) => {
        return {
          id: item.id,
          order: index + 1
        };
      });
      return [...childrens, { id: null, order: childrens?.length + 1 }];
    },
    postCategory() {
      return this.$store.getters["POST_CATEGORY/postCategory"];
    },
    postCategories() {
      return this.$store.getters["POST_CATEGORY/postCategories"];
    },
    postCategoryStatusRequest() {
      return this.$store.getters["POST_CATEGORY/statusRequest"];
    },
    postCategoriesTreeList() {
      return this.$store.getters["POST_CATEGORY/postCategoriesTreeList"];
    },
    uploadedThumbnail() {
      return this.$store.getters["POST_CATEGORY/uploadedThumbnail"];
    }
  },

  methods: {
    async onUploadThumbnail() {
      await this.uploadThumbnail(
        toFormData({
          file: this.postCategory.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.postCategoryStatusRequest.value === "success-uploadThumbnail") {
        this.postCategory.thumbnail = this.uploadedThumbnail;
      } else if (
        this.postCategoryStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh bìa",
          message: this.postCategoryStatusRequest.message
        });

        return;
      }
    },

    onHasParent() {
      if (!this.hasParent) {
        this.postCategory.parent_id = null;
        this.postCategory.swap = null;
        this.postCategory.parent_show = 1;
      }
    },

    openModalFolderExample() {
      this.$modal.show({ name: "modal-folder-example" });
    },

    resetPostCategory() {
      this.$store.dispatch("POST_CATEGORY/resetPostCategory");
    },

    async updatePostCategory() {
      const isValid = this.$refs.formRef.validate();

      if (!isValid) return;

      const routeQuery = this.$route.query;

      if (this.postCategory.thumbnail instanceof File) {
        console.log(1);
        await this.onUploadThumbnail();
      }

      await this.$store.dispatch("POST_CATEGORY/updatePostCategory", {
        data: { id: this.postCategory.id, form: this.postCategory },
        routeQuery: {
          search: routeQuery.search,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });

      // Alert
      if (
        this.postCategoryStatusRequest.value === "success-updatePostCategory"
      ) {
        await this.$store.dispatch("POST_CATEGORY/getAllPostCategories");

        showToastActionAlert(this, { message: "Đã lưu cập nhật" });
      } else if (
        this.postCategoryStatusRequest.value === "error-updatePostCategory"
      ) {
        showModalAlertError(this, {
          title: "Lỗi cập nhật folder",
          message: this.postCategoryStatusRequest.message.message
        });
      }
    },

    async deletePostCategory() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa loại sản phẩm <strong>"${this.postCategory.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("POST_CATEGORY/deletePostCategories", {
                data: {
                  ids: [this.postCategory.id]
                }
              });
              if (
                this.postCategoryStatusRequest.value ===
                "success-deletePostCategories"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
                await this.$store.dispatch(
                  "POST_CATEGORY/getAllPostCategories"
                );
              }
            }
          }
        }
      });
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      await this.$store.dispatch("POST_CATEGORY/uploadThumbnail", formData);
    }
  }
};
</script>

<style lang="scss" scoped></style>
