<template>
  <div>
    <v-row class="pl-5">
      <!-- Start: Filter area -->
      <!-- End: Filter area -->
      <!-- Start: Main area -->
      <v-col cols="8" class="overflow-hidden py-0">
        <!-- Start: Top actions -->
        <top-action
          :search-key="searchKey"
          @updateSearchKey="updateSearchKey($event)"
        ></top-action>
        <!-- End: Top actions -->
        <!-- Start: Data table -->
        <div class="mr-5 p-4 h-full bg-white folder-container">
          <post-folder :search-key="searchKey"></post-folder>
        </div>
        <!-- End: Data table -->
      </v-col>
      <card-form-category class="py-0"></card-form-category>

      <!-- End: Main area -->
    </v-row>
    <modal-folder-post></modal-folder-post>
  </div>
</template>

<script>
import PostFolder from "./components/PostFolder";
// import DataTableItems from "./components/DataTableItems";
import CardFormCategory from "./components/CardFormCategory";
import TopAction from "./components/TopAction";
import ModalFolderPost from "./components/modal/ModalFolderPost";

export default {
  components: {
    // DataTableItems,
    PostFolder,
    CardFormCategory,
    TopAction,
    ModalFolderPost
  },
  data() {
    return {
      searchKey: null
    };
  },
  async created() {
    await this.getPostCategoriesByTreeList();

    await this.$store.dispatch("POST_CATEGORY/getAllPostCategories");
  },
  methods: {
    // pushRouter() {
    //   this.$router.push({
    //     name: "website_post-categories",
    //     query: {
    //       search:
    //         this.searchKey && this.searchKey.length > 0
    //           ? this.searchKey
    //           : undefined,
    //       limit: this.itemPerPage,
    //       page: this.currentPage
    //     }
    //   });
    // },

    // async getPostCategories(query) {
    //   if (query.page) {
    //     // Set current page
    //     this.currentPage = parseInt(query.page);
    //     // Set item per page
    //     this.itemPerPage = parseInt(query.limit);
    //     // Set search key
    //     this.searchKey = query.search || null;

    //     // Get post categories
    //     await this.$store.dispatch("POST_CATEGORY/getPostCategories", {
    //       search: this.searchKey,
    //       per_page: this.itemPerPage,
    //       cur_page: this.currentPage
    //     });
    //   } else {
    //     // Push router query
    //     await this.pushRouter();
    //   }
    // },

    // async getPostCategoriesByTreeList() {
    //   await this.$store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
    // },

    // updateCurrentPage(page) {
    //   if (this.currentPage === page) return;
    //   this.currentPage = page;
    //   this.selectedPostCategories = [];
    //   // Push router query
    //   this.pushRouter();
    // },

    // async updateItemPerPage(num) {
    //   if (this.itemPerPage === num) return;
    //   this.itemPerPage = num;
    //   this.currentPage = 1;
    //   this.selectedPostCategories = [];
    //   // Push router query
    //   this.pushRouter();
    // },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
    },

    async getPostCategoriesByTreeList() {
      await this.$store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
    }
  }
};
</script>

<style lang="scss" scoped>
.folder-container {
  overflow-x: scroll;
  height: calc(100vh - 64px - 53px - 40px - 44px);
}
</style>
