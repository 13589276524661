<template>
  <v-menu v-model="menu" :close-on-content-click="false" nudge-top="-40px">
    <template v-slot:activator="{ on, attrs }">
      <tp-text-field
        v-bind="attrs"
        v-on="on"
        placeholder="Nhập tên folder"
        readonly
        :validate="validate"
        :value="selectedItemName"
      ></tp-text-field>
    </template>

    <v-card class="category-tree__content">
      <v-radio-group
        v-model="selectedItem"
        class="text-body-1 mt-0"
        dense
        hide-details
        @input="onSelectItem(selectedItemObj)"
      >
        <v-treeview
          activatable
          color="primary"
          dense
          hoverable
          open-all
          shaped
          :items="postCategoriesTreeList"
        >
          <template #prepend="{item}">
            <v-radio :value="item.id" @click="onSelectItem(item)"></v-radio>
          </template>
        </v-treeview>
      </v-radio-group>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "CategorySingleSelector",

  props: {
    validate: {
      type: [String, Object],
      default: ""
    },
    value: {
      type: Number,
      default: 0
    }
  },

  computed: {
    postCategoriesTreeList() {
      return this.$store.getters["POST_CATEGORY/postCategoriesTreeList"];
    },
    allPostCategories() {
      return this.$store.getters["POST_CATEGORY/allPostCategories"];
    },
    selectedItemName() {
      return this.selectedItemObj?.name;
    }
  },

  data() {
    return {
      menu: false,
      selectedItem: null,
      selectedItemObj: null
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.selectedItem = newVal;
        this.selectedItemObj = this.allPostCategories.find(
          item => item.id === this.selectedItem
        );
      }
    }
  },

  async created() {
    await this.$store.dispatch("POST_CATEGORY/getAllPostCategories");

    if (this.selectedItem) {
      this.selectedItemObj = this.allPostCategories.find(
        item => item.id === this.selectedItem
      );
    }
  },

  methods: {
    onSelectItem(item) {
      this.selectedItemObj = item;
      this.$emit("input", this.selectedItem);
      this.$emit("change", this.selectedItemObj);
      this.menu = false;
    }
  }
};
</script>
<style lang="scss">
.category-tree__content {
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
