<template>
  <div class="h-full">
    <base-tree
      v-if="
        postCategoryStatusRequest.value !== 'loading-sortPostCategories' &&
          postCategoryStatusRequest.value != 'loading-getAllPostCategories'
      "
      :data="allPostCategories"
      :search-key="searchKey"
      @click="onSelectItem"
      @sort="sortData"
    ></base-tree>
    <div v-else>
      <v-overlay>
        <v-progress-circular
          color="grey lighten-2"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import BaseTree from "./BaseTree.vue";

export default {
  components: {
    BaseTree
  },
  props: {
    searchKey: {
      type: null
    }
  },
  watch: {
    async searchKey() {
      await this.$store.dispatch(
        "POST_CATEGORY/getAllPostCategories",
        this.$props.searchKey
      );
    }
  },

  computed: {
    allPostCategories() {
      return this.$store.getters["POST_CATEGORY/allPostCategories"];
    },
    postCategoryStatusRequest() {
      return this.$store.getters["POST_CATEGORY/statusRequest"];
    },
    postCategoriesTreeList() {
      return this.$store.getters["POST_CATEGORY/postCategoriesTreeList"];
    }
  },
  methods: {
    async onSelectItem(selectItem) {
      await this.$store.dispatch(
        "POST_CATEGORY/getPostCategoryById",
        selectItem.id
      );
    },
    async sortData(val) {
      await this.$store.dispatch("POST_CATEGORY/sortPostCategories", val);
    }
  }
};
</script>
